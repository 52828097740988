.pagination {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.pagination li {
    display: inline-block;
    padding-top: 3px;
    padding-left: 15px;
    padding-right: 15px;
}

.pagination li.active {
    background: #000;
}

.pagination li.active a {
    color: #fff;
}
